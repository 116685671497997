<!-- This modal is imported into feature-view.vue as a tab. This tab lists all promotion item groups that are currently attached to a feature and allows the user to remove them from the feature -->
<template>
  <v-container class="ma-0 pa-0" fluid v-resize="onResize">
    <v-data-table
      :headers="headers"
      :height="tableHeight"
      :items="items"
      loading-text="Retrieving Existing Data...Please Wait"
      :search="search"
      :loading="loading"
      :class="table"
      class="rounded-0"
      group-by="promo_item_group.id"
      dense
      fixed-header
      hide-default-footer
      item-key="id"
      loader-height="5"
      no-data-text="No Data to Display"
      :items-per-page="pageSize">

      <!-- <template #top>
        <v-divider/>
        <v-toolbar floating class="fill" flat>
          <v-row align="center">
            <v-col md="3" lg="3">
              <v-text-field :class="body" class="mt-7" label="Filter Items" outlined dense background-color="input"
                prepend-inner-icon="mdi-filter-outline" v-model="search">
              </v-text-field>
            </v-col>
          </v-row>
        </v-toolbar>
      </template> -->

      <template #[`group.header`]="{ group, items, headers}">
        <td :colspan="headers.length">
          <v-row align="center">
            <v-col class='truncate'>
              <span>
                <b>Promo Item Group</b><br>
                <div class='truncate'>
                {{items[0].promo_item_group.name}}
                </div>
              </span>
            </v-col>
            <v-col class='truncate'>
              <span>
                <b>Promo Name</b><br>
                {{items[0].promo.name}}
              </span>
            </v-col>
            <v-col>
              <span>
                <b>Promo Type</b><br>
                {{items[0].promo.promotion_type.name}}
              </span>
            </v-col>
            <v-col>
              <span>
                <b>Promo Start Date</b><br>
                {{formatDateDisplay(items[0].promo.start_dt)}}
              </span>
            </v-col>
            <v-col>
              <span>
                <b>Promo End Date</b><br>
                {{formatDateDisplay(items[0].promo.end_dt)}}
              </span>
            </v-col>
            <v-col v-if="items[0].promo_item_group.buy_item">
              <span>
                <b>Buy Item?</b><br>
                <v-icon dense>mdi-check-bold</v-icon>
              </span>
            </v-col>
            <v-col v-if="items[0].promo_item_group.get_item">
              <span>
                <b>Get Item?</b><br>
                <v-icon dense>mdi-check-bold</v-icon>
              </span>
            </v-col>
            <v-col v-if="(items[0].promo_item_group.get_item || items[0].promo_item_group.buy_item)">
              <span>
                <b>Qty</b><br>
                {{items[0].promo_item_group.qty}}
              </span>
            </v-col>
            <v-col>
              <span>
                <b>Billing Mode</b><br>
                {{items[0].promo.billing_mode.name}}
              </span>
            </v-col>
            <v-col align="right">
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-btn small icon v-on="on"
                    class="mr-6"
                    @click="feature.promo_item_group_ids.includes(group) ? unlinkPromo(group) : linkPromo(items[0])"
                    :color="feature.promo_item_group_ids.includes(group) ? 'red' : 'green'">
                    <v-icon>
                      {{feature.promo_item_group_ids.includes(group) ? 'mdi-trash-can' : 'mdi-plus'}}
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{feature.promo_item_group_ids.includes(group) ? 'Remove' : 'Add'}}</span>
              </v-tooltip>
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-btn small icon v-on="on"
                    class="mr-2"
                    @click='openLink(items[0])'
                    >
                    <v-icon>
                      mdi-open-in-new
                    </v-icon>
                  </v-btn>
                </template>
                <span>Promotion Portal</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </td>
      </template>

      <template #[`item.promotion_price`]="{ item }">
        <span v-if="item.promo_item_group.promotion_price">
          ${{formatCurrencyDisplay(item.promo_item_group.promotion_price)}}
        </span>
        <span v-else-if="item.promo.promotion_price">
          ${{formatCurrencyDisplay(item.promo.promotion_price)}}
        </span>
        <span v-else>-</span>
      </template>

      <template #[`item.rebate_amount`]="{ item }">
        <span v-if="item.promo_item_group.rebate_amount">
          ${{ formatCurrencyDisplay(item.promo_item_group.rebate_amount) }}
        </span>
        <span v-else-if="item.promo.rebate_amount">
          ${{formatCurrencyDisplay(item.promo.rebate_amount)}}</span>
        <span v-else>-</span>
      </template>

      <template #[`item.billback_allowance`]="{ item }">
        <span v-if="item.accrual_amt">
          ${{formatCurrencyDisplay(item.accrual_amt)}}
        </span>
        <span v-else-if="item.promo_item_group.billback_allowance">
          ${{formatCurrencyDisplay(item.promo_item_group.billback_allowance)}}
        </span>
        <span v-else-if="item.promo.billback_allowance">
          ${{formatCurrencyDisplay(item.promo.billback_allowance)}}
        </span>
        <span v-else>-</span>
      </template>

      <template #[`item.amap_allowance`]="{ item }">
        <span v-if="item.promo_item_group.amap_allowance">
          ${{formatCurrencyDisplay(item.promo_item_group.amap_allowance)}}
        </span>
        <span v-else-if="item.promo.amap_allowance">
          ${{formatCurrencyDisplay(item.promo.amap_allowance)}}
        </span>
        <span v-else>-</span>
      </template>

      <template #[`item.early_buy_allowance`]="{ item }">
        <span v-if="item.promo_item_group.early_buy_allowance">
          ${{formatCurrencyDisplay(item.promo_item_group.early_buy_allowance)}}
        </span>
        <span v-else-if="item.promo.early_buy_allowance">
          ${{formatCurrencyDisplay(item.promo.early_buy_allowance)}}
        </span>
        <span v-else>-</span>
      </template>

      <template #[`item.retail_price`]="{ item }">
        <span v-if="item.retail_price">
          ${{formatCurrencyDisplay(item.retail_price)}}
        </span>
        <span v-else>-</span>
      </template>

    <template v-slot:[`item.cost`]="{ item }">
        <v-tooltip top v-if="(item.item.future_cost == item.item.cost) && (item.item.future_cost_date <= selectedAd.ad_end_date)" >
          <template v-slot:activator="{ on }">
            <span v-on="on" class="primary--text font-weight-bold">
              ${{formatCurrencyDisplay(item.item.cost)}}
            </span>
          </template>
          This item has a cost change on {{$dateConfig.formatDate(item.item.future_cost_date)}} <br>
          The new cost of ${{formatCurrencyDisplay(item.item.cost)}} will be used on this ad
        </v-tooltip>
        <span v-else> ${{formatCurrencyDisplay(item.cost)}} </span>
      </template>

      <template #[`item.item.unit_cost`]="{ item }">
          <span v-if="item.item.qty_per_case">
            ${{formatCurrencyDisplay(calcUnitCost(item))}}
          </span>
          <span v-else> - </span>
        </template>

     <template #[`item.item.gross_profit`]="{ item }">
       {{formatCurrencyDisplay(calcGrossProfit(item))}}%
      </template>

      <template #[`item.start_dt`]="{ item }">
        {{formatDateDisplay(item.start_dt)}}
      </template>

      <template #[`item.end_dt`]="{ item }">
        {{formatDateDisplay(item.end_dt)}}
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import { utils } from '@/mixins/utils'
import { displayHelpers } from '@/mixins/display-helpers'
import { format } from '@/mixins/format'
import { displayAlert } from '@/mixins/alert'
export default {
  name: 'FeaturePromoItems',
  mixins: [displayHelpers, format, utils, displayAlert],
  props: [
    'selectedAd',
    'feature',
    'showInfo',
    'adParty'
  ],
  data: () => ({
    component: '',
    loading: false,
    modal: false,
    promoInfo: false,
    items: [],
    groupLength: 0,
    selectedGroup: {},
    search: '',
    responsible_party_id: '49b5f50f-6bf8-4315-9366-5d993572277d',
    tableHeight: '',
    offset: true,
    page: 1,
    pageSize: 1000,
    pageSizes: [50, 100],
    promoID: [],
    promos:[],
    promoItems:[],
    disableNext: false,
    disablePrevious: true,
  }),
  computed: {
    searchParams () {
      return [
        { description: 'Item ID', param: 'item_id' },
        { description: 'UPC', param: 'upc' }
        // { description: 'Vendor Name', param: 'i.vendor_name' },
        // { description: 'Vendor ID', param: 'i.vendor_id' },
        // { description: 'Promo Name', param: 'c.promo.name' },
        // { description: 'Promo Type', param: 'c.promo.promotion_type.name' }
      ]
    },
    headers () {
      let headers = [
        { sortable: false, filterable: false, class: 'accent white--text' },
        { text: 'Item', groupable: false, align: 'start', sortable: false, class: 'accent white--text', value: 'item.item_id', show: true },
        { text: 'GTIN', groupable: false, align: 'start', sortable: false, class: 'accent white--text', value: 'item.gtin', show: this.$auth.tenant === 'pricechopper' },
        { text: 'Source', groupable: false, align: 'start', sortable: false, class: 'accent white--text', value: 'item.source', show: true },
        { text: 'Name', groupable: false, align: 'start', sortable: false, class: 'accent white--text', value: 'item.description', show: true },
        { text: 'Promotion Price', groupable: false, align: 'start', sortable: false, class: 'accent white--text', value: 'promotion_price', show: true },
        { text: 'Scan Allowance', groupable: false, align: 'start', sortable: false, class: 'accent white--text', value: 'rebate_amount', show: true },
        { text: 'Billback Allowance', groupable: false, align: 'start', sortable: false, class: 'accent white--text', value: 'billback_allowance', show: true },
        { text: 'AMAP Allowance', groupable: false, align: 'start', sortable: false, class: 'accent white--text', value: 'amap_allowance', show: true },
        { text: 'Early Buy Allowance', groupable: false, align: 'start', sortable: false, class: 'accent white--text', value: 'early_buy_allowance', show: true },
        { text: 'Retail Price', groupable: false, align: 'start', sortable: false, class: 'accent white--text', value: 'retail_price', show: true },
        { text: 'Case Cost', groupable: false, align: 'start', sortable: false, class: 'accent white--text', value: 'cost', show: true },
        { text: 'Net Unit Cost', groupable: false, align: 'start', sortable: false, class: 'accent white--text', value: 'item.unit_cost', show: true },
        { text: 'Pack Size', groupable: false, align: 'start', sortable: false, class: 'accent white--text', value: 'item.pack_size', show: true },
        { text: 'Gross Profit %', groupable: false, align: 'start', sortable: false, class: 'accent white--text', value: 'item.gross_profit', show: true },
      ]
      return headers.filter(item => item.show)
    },
    lengthRefresh() {
      return (this.feature.promo_item_group_ids.length == this.groupLength)
    }
  },
  watch: {
    showInfo: {
      immediate: true,
      handler () {
        if (!this.showInfo) {
          this.tableHeight = window.innerHeight - 265
        } else {
          this.tableHeight = window.innerHeight - 415
        }
      }
    }
  },
  async created () {
    if (!this.showInfo) {
      this.tableHeight = window.innerHeight - 265
    } else {
      this.tableHeight = window.innerHeight - 430
    }
    this.$root.$on('get-items', () => this.loopPromoItemGroups())
    this.loopPromoItemGroups()
    this.groupLength = this.feature.promo_item_group_ids.length
  },
  methods: {
    onResize () {
      this.tableHeight = window.innerHeight - 430
    },
    itemRowBackground (item) {
      return this.btnToggle === 1 && this.searchResults.indexOf(item) % 2 !== 1 ? 'grey lighten-2' : ''
    },
    async loopPromoItemGroups () {
      this.items = []
      this.loading = true
      const promises = this.feature.promo_item_group_ids.map(id => {
        return this.$PromoItemGroup.get(id).then(r => r?.data?.promo_id)
      })
      try {
        const { fulfilled, rejected } = await this.getAllSettled(promises, true)
        if (fulfilled.length > 0) {
          const promoIds = Array.from(new Set(fulfilled))
          const promoItems = await this.getPromoItems(promoIds)
          this.items = promoItems
        }
        if (rejected.length > 0) throw rejected
      } catch (err) {
        if (Array.isArray(err)) {
          return this.handleNotFoundErrors(err)
        }
        this.handleError(err)
      } finally {
        this.loading = false
      }
    },
    handleNotFoundErrors (errs) {
      const notFoundIds = []
      errs = errs.flatMap(e => {
        const data = e?.reason?.response?.data
        if (data?.tags?.type === 'NotFoundError' && data?.extras?.type === 'PromoItemGroup') {
          notFoundIds.push(data.extras.id)
          this.feature.promo_item_group_ids = this.feature.promo_item_group_ids.filter(id => id !== data.extras.id)
          return []
        }
        return e
      })
      if (notFoundIds.length > 0) {
        const message = `${notFoundIds.length} promo item group(s) linked to this feature could not be found and have been removed. Group id(s): ${notFoundIds.join(', ')}`
        errs.push(new Error(message))
        this.unlinkPromo(notFoundIds.join(','))
      }
      return this.handleError(errs)
    },
    async getPromoItems(promoIds) {
      const promises = promoIds.flatMap(async id => {
        const { data: promo } = await this.$Promos.getPromoById(id)
        const res = await this.$PromoItem.getItemsByPromo(id, { limit: 2000, source: this.adParty.distribution_center })
        if (res?.data?.length > 0) {
          return this.processPromoItems(res.data, promo)
        }
        return []
      })
      const { fulfilled, rejected } = await this.getAllSettled(promises, true)
      if (rejected.length > 0) throw rejected
      return fulfilled
    },
    processPromoItems(promoItems, promo) {
      const { promo_item_group_ids } = this.feature;
      const promoGroupSeenItems = new Map(); // Map to track seen items per promo group            
      const getPromoGroupKey = (item) => item.promo_item_group.id;

      // Initialize map with empty sets for each promo group
      promoItems.forEach(item => {
        const groupKey = getPromoGroupKey(item);
        if (!promoGroupSeenItems.has(groupKey)) {
          promoGroupSeenItems.set(groupKey, new Set());
        }
      });

      // Split items into sourceItems and isVmcItems
      const sourceItems = promoItems.filter(item => item.item.source === this.adParty.distribution_center);
      const isVmcItems = promoItems.filter(item => item.item.is_vmc === true);

      // Mark sourceItems as seen in their respective promo groups
      sourceItems.forEach(item => {
        const groupKey = getPromoGroupKey(item);
        const seenSet = promoGroupSeenItems.get(groupKey);
        seenSet.add(item.item.item_id);
      });

      // Filter out duplicates from isVmcItems based on the same promo group
      const uniqueIsVmcItems = isVmcItems.filter(item => {
        const groupKey = getPromoGroupKey(item);
        const seenSet = promoGroupSeenItems.get(groupKey);
        if (!seenSet.has(item.item.item_id)) {
          seenSet.add(item.item.item_id);
          return true;
        }
        return false;
      });

      // Combine both arrays
      const combinedItems = [...sourceItems, ...uniqueIsVmcItems];

      // Format and filter the combined items
      return combinedItems.flatMap(item => {
        if (promo_item_group_ids.includes(item.promo_item_group.id)) {
          item.promo = promo
          return this.formatPromoItem(item)
        }
        return []
      })
    },
    formatPromoItem(promoItem) {
      const retail = promoItem.retail_price || promoItem.item.sale_price
      const cost = promoItem.cost || promoItem.item.cost

      promoItem.retail_price = retail
      promoItem.cost = cost
      return promoItem
    },

    calcGrossProfit(item){
      const ad_retail = parseFloat(this.feature.ad_retail)
      const ad_count = parseFloat(this.feature.count)
      const billback_allowance = item.accrual_amt ? item.accrual_amt : item.promo_item_group.billback_allowance

      return Math.round(((((ad_retail / ad_count) * item.item.qty_per_case) 
      - (item.cost) + (item.promo_item_group.rebate_amount * item.item.qty_per_case)
      + (billback_allowance) + (item.promo_item_group.early_buy_allowance) + (item.promo_item_group.amap_allowance))
      / ((ad_retail / ad_count) * item.item.qty_per_case)) * 100) 
    },
    async unlinkPromo (id) {
      this.feature.promo_item_group_ids = this._.pull(this.feature.promo_item_group_ids, id)
      this.promos = []
      this.items = []
      this.promoID = []
      this.update('Removed')
    },
    async update (actions) {
      await this.$Features.updateAdFeature(this.feature.id, this.feature)
        .then(() => {
          this.$root.$emit('refresh-search')
          this.$store.dispatch('setSnackbar', { status: 'success', text: `Promotion Item Group ${actions}` })
        }).catch(err => {
          this.$store.dispatch('setSnackbar', { status: 'error', text: `Failed to Update Feature due to ${err?.response?.data?.message || err.message}` })
        }).finally(this.loopPromoItemGroups())
    },
    calcUnitCost(item){
      if (item.accrual_amt) {
        return ((item.cost -(item.promo_item_group.rebate_amount+item.accrual_amt+item.promo_item_group.amap_allowance+item.promo_item_group.early_buy_allowance))/item.item.qty_per_case)
      }
      else if(item.promo.promo_category.billback_promotion){
        return ((item.cost -(item.promo_item_group.rebate_amount+item.promo_item_group.billback_allowance+item.promo_item_group.amap_allowance+item.promo_item_group.early_buy_allowance))/item.item.qty_per_case)
      } else {
        return (item.cost/item.item.qty_per_case-(item.promo_item_group.rebate_amount+item.promo_item_group.billback_allowance+item.promo_item_group.amap_allowance+item.promo_item_group.early_buy_allowance))
      }
    },
    openLink(pig){
      let baseUrl = process.env.VUE_APP_PROMO_PORTAL
      let prefix = this.$auth.tenantURLPrefix
      if (prefix) {
        baseUrl = `${prefix}.${baseUrl}`
      }
      const url = `https://${baseUrl}/promo?id=${pig.promo.id}`
      window.open(url, 'tab')
    }
  }
}
</script>
<style scoped>
  .active {
    box-shadow: 0px 2px 10px 3px rgba(120,120,120,0.75);
    background: #385F73;
    color: white !important;
  }
  .truncate {
    display:inline;
    width:20px;
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis
  }
</style>